export const translations = {
    English: {
      about: "About",
      expertise: "Services",
      education: "Education",
      experience: "Experience",
      contact: "Contact",
      name: "I am Moussa Doumbia",
      title: "International Business Facilitator & Student",
      getInTouch: "Get in Touch",
      downloadCV: "Download CV",
      skills: "Skills & Languages",
      professionalSkills: "Professional Skills",
      languages: "Languages",
      negotiationSkills: "Negotiation Skills",
      communicationSkills: "Communication Skills",
      problemSolving: "Problem Solving",
      culturalAwareness: "Cultural Awareness",
      businessNetworking: "Business Networking",
      marketResearch: "Market Research",
      aboutHeadline: "About",
      expertiseHeadline: "Expertise",
      educationHeadline: "Education",
      experienceHeadline: "Experience",
      contactHeadline: "Contact",
      university: "Ankara Yıldırım Beyazıt University",
      degree: "Bachelor of Mechanical Engineering",
      year: "2021 - 2026",
      foreignTrade: "Foreign Trade",
      tourismServices: "Tourism Services",
      healthTourism: "Health Tourism",
      educationPlacement: "Education Placement",
      businessNetworking: "Business Networking",
      foreignTradeDesc: "Market research, customer acquisition, supply chain management, and export/import facilitation between Africa and Turkey.",
      tourismServicesDesc: "Interpretation, tourist guiding, and escorting services for visitors to Turkey.",
      healthTourismDesc: "Facilitating treatment at Turkey's top hospitals for international patients.",
      educationPlacementDesc: "Assisting students with placements in universities across Turkey, Dubai, and Saudi Arabia.",
      aboutContent: "As an Ivorian student pursuing my education in Turkey, I've positioned myself as a vital bridge between African and Turkish businesses. My unique perspective and cultural understanding allow me to identify and connect companies in Africa with their ideal partners in Turkey. Specializing in foreign trade, tourism, health tourism, and education, I facilitate mutually beneficial relationships that drive growth and innovation. With a deep understanding of both African needs and Turkish capabilities, I'm committed to fostering international cooperation and creating value for businesses on both continents.",
      experienceIntern: {
        title: "International Business Development Intern",
        company: "Global Connections Ltd.",
        description: "Researched potential markets in West Africa for Turkish textile products. Developed a comprehensive report on market trends, consumer behavior, and competitive landscape."
      },
      experienceAmbassador: {
        title: "Student Ambassador",
        company: "Ankara Yıldırım Beyazıt University",
        description: "Serve as a liaison between international students and university administration. Organize cultural exchange events and assist new students in adapting to life in Turkey."
      },
      experienceTranslator: {
        title: "Volunteer Translator",
        company: "African-Turkish Business Forum",
        description: "Provided French-Turkish translation services during a major business conference, facilitating communication between African and Turkish entrepreneurs."
      },
      keyPoints: [
        "Cross-cultural expertise",
        "Business networking",
        "Market research",
        "International cooperation"
      ],
      languageProficiency: {
        Native: "Native",
        Fluent: "Fluent",
        Intermediate: "Intermediate",
        Basic: "Basic"
      },
      languageNames: {
        French: "French",
        Turkish: "Turkish",
        English: "English",
        Arabic: "Arabic",
        Bambara: "Bambara"
      }
    },
    Turkish: {
      about: "Hakkımda",
      expertise: "Hizmetler",
      education: "Eğitim",
      experience: "Deneyim",
      contact: "İletişim",
      name: "Ben Moussa Doumbia",
      title: "Uluslararası İş Kolaylaştırıcısı & Öğrenci",
      getInTouch: "İletişime Geçin",
      downloadCV: "CV İndir",
      skills: "Beceriler ve Diller",
      professionalSkills: "Profesyonel Beceriler",
      languages: "Diller",
      negotiationSkills: "Müzakere Becerileri",
      communicationSkills: "İletişim Becerileri",
      problemSolving: "Problem Çözme",
      culturalAwareness: "Kültürel Farkındalık",
      businessNetworking: "İş Ağı Oluşturma",
      marketResearch: "Pazar Araştırması",
      aboutHeadline: "Hakkımda",
      expertiseHeadline: "Uzmanlık",
      educationHeadline: "Eğitim",
      experienceHeadline: "Deneyim",
      contactHeadline: "İletişim",
      university: "Ankara Yıldırım Beyazıt Üniversitesi",
      degree: "Makine Mühendisliği Lisans",
      year: "2021 - 2026",
      foreignTrade: "Dış Ticaret",
      tourismServices: "Turizm Hizmetleri",
      healthTourism: "Sağlık Turizmi",
      educationPlacement: "Eğitim Yerleştirme",
      businessNetworking: "İş Ağı Oluşturma",
      foreignTradeDesc: "Afrika ve Türkiye arasında pazar araştırması, müşteri edinme, tedarik zinciri yönetimi ve ihracat/ithalat kolaylaştırma.",
      tourismServicesDesc: "Türkiye'ye gelen ziyaretçiler için tercümanlık, turist rehberliği ve eşlik etme hizmetleri.",
      healthTourismDesc: "Uluslararası hastalar için Türkiye'nin en iyi hastanelerinde tedavi imkanı sağlama.",
      educationPlacementDesc: "Öğrencilere Türkiye, Dubai ve Suudi Arabistan'daki üniversitelere yerleştirilmelerinde yardımcı olma.",
      aboutContent: "Türkiye'de eğitim gören bir Fildişi Sahilli öğrenci olarak, kendimi Afrika ve Türk işletmeleri arasında hayati bir köprü olarak konumlandırdım. Benzersiz bakış açım ve kültürel anlayışım, Afrika'daki şirketleri Türkiye'deki ideal ortaklarıyla tanımlamamı ve bağlantı kurmamı sağlıyor. Dış ticaret, turizm, sağlık turizmi ve eğitim alanlarında uzmanlaşarak, büyümeyi ve yeniliği teşvik eden karşılıkli fayda sağlayan ilişkileri kolaylaştırıyorum. Hem Afrika'nın ihtiyaçlarını hem de Türkiye'nin yeteneklerini derinden anlayarak, uluslararası işbirliğini teşvik etmeye ve her iki kıtadaki işletmeler için değer yaratmaya kendimi adadım.",
      experienceIntern: {
        title: "Uluslararası İş Geliştirme Stajyeri",
        company: "Global Connections Ltd.",
        description: "Türk tekstil ürünleri için Batı Afrika'daki potansiyel pazarları araştırdı. Pazar trendleri, tüketici davranışları ve rekabet ortamı hakkında kapsamlı bir rapor hazırladı."
      },
      experienceAmbassador: {
        title: "Öğrenci Elçisi",
        company: "Ankara Yıldırım Beyazıt Üniversitesi",
        description: "Uluslararası öğrenciler ve üniversite yönetimi arasında irtibat görevi görüyor. Kültürel değişim etkinlikleri düzenliyor ve yeni öğrencilerin Türkiye'deki yaşama uyum sağlamasına yardımcı oluyor."
      },
      experienceTranslator: {
        title: "Gönüllü Tercüman",
        company: "Afrika-Türkiye İş Forumu",
        description: "Önemli bir iş konferansında Fransızca-Türkçe çeviri hizmetleri sağladı, Afrikalı ve Türk girişimciler arasındaki iletişimi kolaylaştırdı."
      },
      keyPoints: [
        "Kültürlerarası uzmanlık",
        "İş ağı oluşturma",
        "Pazar araştırması",
        "Uluslararası işbirliği"
      ],
      languageProficiency: {
        Native: "Ana Dil",
        Fluent: "Akıcı",
        Intermediate: "Orta",
        Basic: "Temel"
      },
      languageNames: {
        French: "Fransızca",
        Turkish: "Türkçe",
        English: "İngilizce",
        Arabic: "Arapça",
        Bambara: "Bambara"
      }
    },
    French: {
      about: "À Propos",
      expertise: "Services",
      education: "Éducation",
      experience: "Expérience",
      contact: "Contact",
      name: "Je suis Moussa Doumbia",
      title: "Facilitateur d'Affaires Internationales & Étudiant",
      getInTouch: "Contactez-Moi",
      downloadCV: "Télécharger CV",
      skills: "Compétences et Langues",
      professionalSkills: "Compétences Professionnelles",
      languages: "Langues",
      negotiationSkills: "Compétences en Négociation",
      communicationSkills: "Compétences en Communication",
      problemSolving: "Résolution de Problèmes",
      culturalAwareness: "Sensibilité Culturelle",
      businessNetworking: "Réseautage d'Affaires",
      marketResearch: "Étude de Marché",
      aboutHeadline: "À Propos",
      expertiseHeadline: "Expertise",
      educationHeadline: "Éducation",
      experienceHeadline: "Expérience",
      contactHeadline: "Contact",
      university: "Université Yıldırım Beyazıt d'Ankara",
      degree: "Licence en Génie Mécanique",
      year: "2021 - 2026",
      foreignTrade: "Commerce Extérieur",
      tourismServices: "Services Touristiques",
      healthTourism: "Tourisme de Santé",
      educationPlacement: "Placement Éducatif",
      businessNetworking: "Réseautage d'Affaires",
      foreignTradeDesc: "Étude de marché, acquisition de clients, gestion de la chaîne d'approvisionnement et facilitation des exportations/importations entre l'Afrique et la Turquie.",
      tourismServicesDesc: "Services d'interprétation, de guide touristique et d'accompagnement pour les visiteurs en Turquie.",
      healthTourismDesc: "Facilitation des traitements dans les meilleurs hôpitaux de Turquie pour les patients internationaux.",
      educationPlacementDesc: "Assistance aux étudiants pour leur placement dans des universités en Turquie, à Dubaï et en Arabie Saoudite.",
      aboutContent: "En tant qu'étudiant ivoirien poursuivant mes études en Turquie, je me suis positionné comme un pont essentiel entre les entreprises africaines et turques. Ma perspective unique et ma compréhension culturelle me permettent d'identifier et de connecter les entreprises en Afrique avec leurs partenaires idéaux en Turquie. Spécialisé dans le commerce extérieur, le tourisme, le tourisme de santé et l'éducation, je facilite des relations mutuellement bénéfiques qui stimulent la croissance et l'innovation. Avec une compréhension approfondie des besoins africains et des capacités turques, je m'engage à favoriser la coopération internationale et à créer de la valeur pour les entreprises des deux continents.",
      experienceIntern: {
        title: "Stagiaire en Développement des Affaires Internationales",
        company: "Global Connections Ltd.",
        description: "A recherché des marchés potentiels en Afrique de l'Ouest pour les produits textiles turcs. A développé un rapport complet sur les tendances du marché, le comportement des consommateurs et le paysage concurrentiel."
      },
      experienceAmbassador: {
        title: "Ambassadeur Étudiant",
        company: "Université Yıldırım Beyazıt d'Ankara",
        description: "Sert de liaison entre les étudiants internationaux et l'administration universitaire. Organise des événements d'échange culturel et aide les nouveaux étudiants à s'adapter à la vie en Turquie."
      },
      experienceTranslator: {
        title: "Traducteur Bénévole",
        company: "Forum d'Affaires Afrique-Turquie",
        description: "A fourni des services de traduction français-turc lors d'une importante conférence d'affaires, facilitant la communication entre les entrepreneurs africains et turcs."
      },
      keyPoints: [
        "Expertise interculturelle",
        "Réseautage d'affaires",
        "Étude de marché",
        "Coopération internationale"
      ],
      languageProficiency: {
        Native: "Langue Maternelle",
        Fluent: "Courant",
        Intermediate: "Intermédiaire",
        Basic: "Basique"
      },
      languageNames: {
        French: "Français",
        Turkish: "Turc",
        English: "Anglais",
        Arabic: "Arabe",
        Bambara: "Bambara"
      }
    }
  };