import React from 'react';
import { translations } from '../data/translations';

const Expertise = ({ interests, language }) => {
  const t = translations[language];
  return (
    <section id="expertise" className="py-20 bg-gradient-to-b from-white to-blue-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-blue-800">{t.expertise}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          {interests.map((interest, index) => (
            <div key={index} className="bg-white p-8 rounded-xl shadow-lg transform hover:scale-105 transition duration-300 flex flex-col items-center text-center">
              <div className="text-6xl mb-6 text-blue-600">
                {interest.icon}
              </div>
              <h3 className="text-2xl font-semibold mb-4 text-gray-800">{t[interest.title]}</h3>
              <p className="text-gray-600">{t[interest.descriptionKey]}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Expertise;