import React from 'react';
import { Mail, Phone, Linkedin, MessageCircle } from 'lucide-react';
import { translations } from '../data/translations';

const Contact = ({ contact, language }) => {
  const t = translations[language];
  const phoneNumber = '+905313512439';
  const whatsappLink = `https://wa.me/${phoneNumber.replace(/\+/g, '')}`;

  return (
    <section id="contact" className="py-20 bg-gradient-to-r from-blue-600 to-purple-700 text-white">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold mb-12">{t.contact}</h2>
        <div className="flex flex-col items-center space-y-6 mb-10">
          <ContactItem icon={<Mail size={28} />} text={contact} href={`mailto:${contact}`} />
          <ContactItem icon={<Phone size={28} />} text={phoneNumber} href={`tel:${phoneNumber}`} />
        </div>
        <div className="flex justify-center space-x-8">
          <SocialButton href="#" icon={<Linkedin size={40} />} color="bg-blue-600 hover:bg-blue-700" />
          <SocialButton href={whatsappLink} icon={<MessageCircle size={40} />} color="bg-green-500 hover:bg-green-600" />
        </div>
      </div>
    </section>
  );
};

const ContactItem = ({ icon, text, href }) => (
  <a href={href} className="flex items-center space-x-4 text-xl hover:text-blue-200 transition duration-300">
    <div className="bg-white/20 p-3 rounded-full">{icon}</div>
    <span>{text}</span>
  </a>
);

const SocialButton = ({ href, icon, color }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={`${color} text-white p-5 rounded-full transition duration-300 transform hover:scale-110`}
  >
    {icon}
  </a>
);

export default Contact;