import React from 'react';
import { translations } from '../data/translations';

const AboutMe = ({ about, language }) => {
  const t = translations[language];
  return (
    <section id="about" className="py-20 bg-gradient-to-b from-white to-blue-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-8 text-center text-blue-800">{t.about}</h2>
        <div className="bg-white p-8 rounded-xl shadow-lg max-w-3xl mx-auto">
          <p className="text-lg text-gray-700 leading-relaxed">
            {t.aboutContent}
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;