import React from 'react';
import { translations } from '../data/translations';

const Skills = ({ skills, languages, language }) => {
  const t = translations[language];
  return (
    <section id="skills" className="py-20 bg-gradient-to-b from-blue-800 to-blue-900 text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center">{t.skills}</h2>
        <div className="space-y-12">
          <div className="bg-blue-700 p-8 rounded-xl shadow-lg">
            <h3 className="text-2xl font-semibold mb-6 text-center">{t.professionalSkills}</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {skills.map((skill, index) => (
                <div key={index} className="bg-blue-600 p-4 rounded-lg shadow-md transform hover:scale-105 transition duration-300 h-24 flex items-center justify-center">
                  <p className="font-semibold text-center">{t[skill]}</p>
                </div>
              ))}
            </div>
          </div>
          
          <div className="bg-blue-700 p-8 rounded-xl shadow-lg">
            <h3 className="text-2xl font-semibold mb-6 text-center">{t.languages}</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {languages.map((lang, index) => (
                <div key={index} className={`bg-blue-600 p-4 rounded-lg shadow-md transform hover:scale-105 transition duration-300 h-24 flex flex-col items-center justify-center ${index === languages.length - 1 && languages.length % 2 !== 0 ? 'col-span-2 md:col-span-1' : ''}`}>
                  <p className="font-semibold text-center">{t.languageNames[lang.language]}</p>
                  <p className="text-blue-200 text-center mt-1">{t.languageProficiency[lang.proficiency]}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;